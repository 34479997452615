<template>
  <div>
    <b-carousel
      v-if="banners.length > 0"
      v-model="slide"
      :interval="10000"
      indicators
    >
      <b-carousel-slide v-for="(b, i) in banners" v-bind:key="i">
        <template v-slot:img>
          <div class="card card-custom gutter-b" style="padding-top: 30%">
            <div class="d-flex" style="position: absolute; top: 0; left: 0;right: 0; bottom: 0">
              <div class="flex-grow-1 d-flex align-items-center p-8 card-rounded bgi-no-repeat" style="background-color: #FFF4DE; background-position: center center; background-size: auto 100%;" v-bind:style="bannerbackground(b)">
                <b-row>
                  <b-col>
                    <h4 class="text-danger font-weight-bolder">{{ b.title }}</h4>
                    <p class="text-dark-50 my-5 font-size-xl font-weight-bold">{{ b.desc }}</p>
                    <a v-if="b.label !== ''" v-bind:href="b.link" class="btn btn-danger font-weight-bold py-2 px-6">{{ b.label }}</a>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="row mb-2" v-if="tour">
      <!-- Tournament -->
      <div class="col-12">
        <div class="card card-custom card-stretch" style="background-color: #663259;">
          <div class="card-body display-4 mb-0 text-white font-weight-bolder" :style="bg">
            {{ info.name }}
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  data(){
    return {
      slide: 0,
      sliding: null,
      info: {},
      banners: []
    }
  },
  methods: {
    bannerbackground(banner) {
      if (banner.img !== '') {
        return {
          'background-image': `url(${banner.img})`
        }
      }

      return {}
    },
    recvInfo(no) {
      if (no === undefined) {
        return
      }

      this.axios.get(`/tournaments/${no}`).then(
        ({ data }) => {
          this.info = data
          this.banners = data.banners
        }
      )
    }
  },
  computed: {
    tour() {
      return this.$route.params.tour
    },
    bg() {
      if (this.info != null && this.info.imgbg != null) {
        return {
          'background-image': `url(${this.info.imgbg})`,
          'background-position-x': 'right',
          'background-position-y': 'center',
          'background-size': 'contain'
        }
      }

      return {}
    }
  },
  watch: {
    tour (newVal) {
      if (newVal != null) {
        this.recvInfo(newVal)
      }
    }
  },
  mounted(){
    this.recvInfo(this.tour)
  }
}
</script>

<style>

</style>